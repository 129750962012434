@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.top-50 {
  top: 50%;
}

.-translate-y-1\/2 {
  transform: translateY(-50%);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
.gallery-display {
  display: none;
}

.phone-info {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #1b365d;
  padding: 10px;
  border-radius: 100%;
  z-index: 100;
  box-shadow: -2px 9px 12px 2px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: -2px 9px 12px 2px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: -2px 9px 12px 2px rgba(0, 0, 0, 0.65);
}

.phone-on-mobile {
  display: block;
}
@media only screen and (min-width: 1024px) {
  .phone-on-mobile {
    display: none;
  }
  .phone-info {
    position: static;
    bottom: auto;
    right: auto;
    padding: 0;
    border-radius: 0;
    z-index: 0;
    background: transparent;
    display: block;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .gallery-display {
    display: block;
  }
}

.gallery-image {
  @apply relative;
}
.gallery-image::after {
  content: ' ';
  border-radius: 8px;
  width: calc(100% - 14px);
  height: calc(100% - 15px);

  @apply absolute top-2 right-2 bg-aegean opacity-0 transition-all;
}

.gallery-image:hover:after {
  @apply opacity-80 transition-all;
}

.gallery-image:hover > .gallery-image__title {
  @apply opacity-80 transition-all;
}

.gallery-image__title {
  @apply text-xs text-taxi-yellow opacity-0 absolute transition-all z-50 font-semibold whitespace-pre-wrap;
  bottom: 20px;
  left: 4%;
}

html {
  --background-light: #dee4e7;
  --background-dark: #222222;
}

[data-theme='light'] {
  color-scheme: light;
  --background: var(--background-light);
}

[data-theme='dark'] {
  color-scheme: dark;
  --background: var(--background-dark);
}

.bgGradient {
  background-image: var(--background);
}
